.p-main{
  width: 100rem;
  margin: 0 auto;
  @media (--mobile) {
    width: 100%;
    // padding: 0 3rem;
  }
  .line{
    font-size: 3.2rem;
    letter-spacing: 0.2rem;
    color: #BCBCBC;
    @media (--mobile) {
      font-size: 2.8rem;
    }
  }
  .left{
    justify-content: flex-start;
    @media (--mobile) {
      padding: 0 2rem;
    }
  }
  &-mv{
    width: 100%;
    text-align: right;
    position: relative;
    // margin-top: 10rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 17vw;
    @media (--mobile) {
      margin-bottom: 12rem;
    }
    &__img01{
      width: 60vw;
      height: 35vw;
      background: url(../images/top/img-mv02.jpg) center center / cover no-repeat;
      @media (--mobile) {
        width: 26rem;
        position: absolute;
        top: -6rem;
        padding-top: calc(205 / 266 * 100%); /* calc(画像高さ ÷ 画像横幅 × 100%) */
        background: url(../images/top/img-mv02-sp.jpg) center center / cover no-repeat;
      }
    }
    &__img02{
      width: 23vw;
      height: 28vw;
      position: absolute;
      top: 22vw;
      left: 0;
      text-align: left;
      @media (--mobile) {
        width: 9.5rem;
        height: auto;
        top: 7rem;
        left: 0;
        position: absolute;
      }
      @media (--large) {
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }
    }

    &__text{
      position: absolute;
      font-size: 3vw;
      line-height: 3.6vw;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 7vw;
      left: 18vw;
      text-align: left;
      @media (--mobile) {
        font-size: 2.2rem;
        line-height: 4rem;
        width: 100%;
        left: 0%;
        transform: translate(-10%,-40%);
        top: 0%;
      }
      span{
        background-color: #FFF;
        padding: 0 1rem;
        display: inline-block;
        padding: 0.7rem 2.2rem;
        letter-spacing: 0.2em;
        @media (--mobile) {
          display: inline-block;
          padding: 0.7rem 2.2rem;
          letter-spacing: 0.2em;
        }
      }
    }

  }

  &-concept{
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    // align-items: flex-start;
    // width: 90%;
    // margin: 0 auto 30rem auto;
    position: relative;
    padding-left: calc(50% - 48rem);
    padding-top: 12rem;
    padding-bottom: 10rem;
    margin-bottom: 19rem;
    overflow: hidden;
    @media (--mobile) {
      width: 100%;
      display: block;
      padding-top: 27rem;
      padding-bottom: 28rem;
      margin-top: 20rem;
      margin-bottom: 14rem;
    }
    &__img{
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-6rem);
      @media (--mobile) {
        display: inline-block;
        width: 22rem;
        top: 7rem;
        right: 0;
        left: auto;
        transform: none;
      }
      img{
        width: 37rem;
      }
    }
    &__img02{
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(6rem);
      width: 56rem;
      @media (--mobile) {
        width: 30rem;
        bottom: 0;
        right: 0;
        transform: none;
        left: 0;
      }
      img{
        width: 56rem;
      }
    }
    &__txt{
      margin-right: 4rem;
      width: 60rem;
      // padding-top: 20rem;
      @media (--mobile) {
        padding: 0 2rem;
        width: 100%;
      }
    }
    &__title{
      margin-bottom: 5rem;
      font-size: 3.2rem;
      color: #BCBCBC;
      @media (--mobile) {
        font-size: 2.8rem;
        margin-bottom: 3rem;
      }
    }
    &__copy{
      font-size: 2.6rem;
      letter-spacing: 0.2rem;
      line-height: 4.5rem;
      margin-bottom: 3rem;
      color: #333333;
      letter-spacing: 0.2em;
      @media (--mobile) {
        font-size: 1.8rem;
        line-height: 3.6rem;
        margin-bottom: 1.5rem;
      }
    }
    &__text{
      font-size: 1.3rem;
      line-height: 3rem;
      width: 45rem;
      letter-spacing: 0.2em;
      @media (--mobile) {
        width: 100%;
        margin-bottom: 2rem;
      }
      p{
        margin-bottom: 5rem;
        @media (--mobile) {
          margin-bottom: 3rem;
        }
      }
    }

    &__btn{
      font-size: 1.3rem;
      position: relative;
      padding-left: 3rem;
      @media (--mobile) {
        text-align: right;
        margin-bottom: 4rem;
      }
      span{
        position: relative;
        padding-bottom: 0.5rem;
      }
      span::after{
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
      }
      &::before{
        position: absolute;
        top: 15px;
        left: 5px;
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        border-top: 1px solid #000;
        transform: translateX(-50%);
        @media (--mobile) {
          left: auto;
          right: 50px;
        }
      }
      &::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        border-top: 1px solid #000;
        top: 11px;
        left: -3px;
        transform: rotate(90deg);
        @media (--mobile) {
          left: auto;
          right: 58px;
        }
      }
    }
  }
  &-info{
    padding: 0 2rem;
    margin-bottom: 22rem;
    @media (--mobile) {
      margin-top: 0;
      margin-bottom: 12rem;
    }
    &__title{
      margin-bottom: 5rem;
      @media (--mobile) {
        margin: 0 0rem 3rem 0rem;
      }
    }
    &__detail{
      display: flex;
      justify-content: space-between;
      @media (--mobile) {
        display: block;
      }
    }
    &__img{
      margin-right: 10rem;
      @media (--mobile) {
        margin-right: 0;
      }
    }
    &__img01{
      margin-bottom: 2rem;
      img{
        @media (--mobile) {
          width: 100%;
        }
      }
    }
    &__img02{
      img{
        @media (--mobile) {
          width: 100%;
        }
      }
    }
    &__content{
      width: 100%;
      font-size: 1.5rem;
      line-height: 3rem;

      dl{
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #E3E3E3;
        margin-bottom: 8rem;
        @media (--mobile) {
          display: block;
        }
        dt{
          padding: 5rem 0;
          width: 15%;
          margin-bottom: 1rem;
          border-bottom: 1px solid #E3E3E3;
          font-weight: bold;
          @media (--mobile) {
            width: 100%;
            font-size: 1.4rem;
            border-bottom: none;
            padding: 3rem 0 2.5rem 0;
            margin-bottom: 0;
          }
        }
        dd{
          width: 85%;
          padding: 5rem 0;
          margin-inline-start: 0;
          margin-bottom: 1rem;
          border-bottom: 1px solid #E3E3E3;
          @media (--mobile) {
            width: 100%;
            padding-top: 0;
            padding-bottom: 3rem;
            margin-bottom: 0;
          }
          a{
            text-decoration: underline;
          }
        }
      }
      @media (--mobile) {
        width: 100%;
        background: none;
        margin-top: 4rem;
      }
    }
    .gmap {
      height: 350px;
      overflow: hidden;
      // padding-bottom: 56.25%;
      position: relative;
      }
      .gmap iframe {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      }
  }

  &-exterior{
    &__img{
      width: 100%;
      margin-bottom: 14rem;
      @media (--mobile) {
        margin-bottom: 8rem;
      }
    }
  }

  &-menu{
    margin-bottom: 22rem;
    padding: 0 2rem;
    @media (--mobile) {
      padding: 0 2rem;
      margin-bottom: 12rem;
    }
    &__title{
      margin-bottom: 3rem;
    }
    .accordion {
      margin-left: auto;
      margin-right: auto;
      margin-top: 3rem;
      position: relative;
      width: 100%;
      display: none;
      @media (--mobile) {
        display: block;
      }
      dt:last-of-type{
          @media (--mobile) {
            margin-bottom: 0;
        }
    }
    }
    .accordion-title {
      color: #555555;
      cursor: pointer;
      font-size: 1.5rem;
      padding: 1.5rem 0;
      font-weight: normal;
    }
    .accordion-content {
      display: none;
      padding: 1rem 0rem;
    }
    .accordion-content dl:first-of-type{
      margin-bottom: 0;
    }
    .accordion-content p {
      line-height: 2rem;
      margin-top: 1rem;
    }
    /* タイトルの背景色 */
    .accordion-item .accordion-title {
      background-color: #FFF;
    }
    // .accordion-item:nth-of-type(2) .accordion-title {
    //   background-color: #66cdaa;
    // }
    // .accordion-item:nth-of-type(3) .accordion-title {
    //   background-color: #ff7f7f;
    // }
    /* コンテンツボックスの枠線 */
    .accordion-item .accordion-content {
      background-color: #FFF;
    }

    /* 矢印 */
    .accordion-title {
      position: relative;
    }
    .accordion-title::after {
      border-right: solid 1px #555555;
      border-top: solid 1px #555555;
      content: "";
      display: block;
      height: 8px;
      position: absolute;
      right: 0;
      top: 32%;
      transform: rotate(135deg);
      transition: transform .3s ease-in-out, top .3s ease-in-out;
      width: 8px;
    }
    .accordion-title.open::after {
      top: 45%;
      transform: rotate(-45deg);
    }
    .tab{
      // max-width: 600px;
      margin: 5rem auto 0 auto;
      display: flex;
      justify-content: space-between;
      @media (--mobile) {
        width: 100%;
        display: none;
      }
    }
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      appearance: none;
    }
    .tab-list{
      display: flex;
      width: 20%;
      flex-direction: column;
      font-size: 1.8rem;
      @media (--mobile) {
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-direction: row;
        margin-bottom: 6rem;
      }
    }
    
    .tab-list-item{
      width: 100%;
      padding: 1.5rem 0;
      cursor: pointer;
      border-bottom: 1px solid #E3E3E3;
      letter-spacing: 0.1em;
      text-align: left;
      color: #555555;
      @media (--mobile) {
        width: 48%;
        padding: 3rem 0;
      }
      &:first-of-type{
        border-top: 1px solid #E3E3E3;
      }
      @media (--mobile) {
        &:nth-child(2){
          border-top: 1px solid #E3E3E3;
        }
      }
    }
    .tab-contents-wrap{
      width: 70%;
      @media (--mobile) {
        width: 100%;
      }
    }
    .tab-contents{
      display: none;
      width: 100%;
    }
    
    .is-btn-active{
    }
    
    .is-contents-active{
      display: block;
    }
    dl{
      width: 100%;
      margin-bottom: 6rem;
      // display: flex;
      // flex-wrap: wrap;
      // margin-bottom: 6rem;
      font-size: 1.4rem;
      // justify-content: space-between;
      .menu-inner{
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 3rem;
        padding: 0.5rem 0;
        color: #555555;
        &::after{
          content: "";
          display: block;
          position: absolute;
          top: 1.2rem;
          z-index: 1;
          width: 100%;
          border-bottom: dotted 1px #E3E3E3;
          }
      }
      dt {
        margin:0;
        padding: 0 0.5rem 0 0;
        background-color: #ffffff;
        z-index: 2;
        text-align: left;
        min-width: 13rem;
        @media (--mobile) {
          min-width: 5rem;
        }
      }
      dd {
        margin:0;
        padding: 0 0 0 0.5rem;
        background-color: #ffffff;
        z-index: 2;
        text-align: right;
        min-width: 9rem;
      }
    }
    .tab-content{
      display: flex;
      justify-content: space-between;
      @media (--mobile) {
        display: block;
      }

      dl{
        width: 48%;
        margin-bottom: 6rem;
        @media (--mobile) {
          width: 100%;
          margin-bottom: 0;
          &:last-child{
            margin-bottom: 6rem;
          }
        }
      }
    }
      p{
        font-size: 1.4rem;
        line-height: 2.8rem;
        color: #555555;
        margin-bottom: 1rem;
      }
  }
  &-news{
    padding: 0 2rem;
    margin-bottom: 22rem;
    @media (--mobile) {
      padding: 0 2rem;
      margin-bottom: 10rem;
    }
    ul{
      margin-top: 5rem;
      @media (--mobile) {
        margin-top: 3rem;
      }
      li{
        border-top: 1px solid #e8e8e8;
        width: 100%;
        position: relative;
        &::after{
          border-right: solid 1px #707070;
          border-top: solid 1px #707070;
          content: "";
          display: block;
          width: 1.8rem;
          height: 1.8rem;
          position: absolute;
          right: 3rem;
          top: 45%;
          -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
          @media (--mobile) {
            right: 1rem;
          }
        }
        &:last-child{
          border-bottom: 1px solid #e8e8e8;
        }
        a{
          padding: 5rem 3rem;
          display: flex;
          transition: all .3s;
          @media (--mobile) {
            padding: 3rem 1rem;
            align-items: center;
          }
          &:hover{
            background-color: #f9f9f9;
          }
        }
      }
    }
    &__img{
      margin-right: 10rem;
      @media (--mobile) {
        width: 10rem;
        margin-right: 2rem;
      }
      img{
        width: 18rem;
        height: 18rem;
        object-fit: cover;
        @media (--mobile) {
          width: 10rem;
          height: 10rem;
        }
      }
    }
    &__content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (--mobile) {
        display: block;
      }
    }
    &__data{
      display: flex;
      margin-bottom: 1rem;
      @media (--mobile) {
        display: block;
      }
      span.date{
        margin-right: 5rem;
        font-size: 1.5rem;
        @media (--mobile) {
          font-size: 1.3rem;
          margin-bottom: 1rem;
          display: block;
        }
      }
      span.category{
        ul{
          display: flex;
          margin-top: 0;
          margin-bottom: 0;
          li{
            padding: 0.2rem 0.2rem;
            border: 1px solid #000;
            width: auto;
            font-size: 1rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
    &__tit{
      font-size: 1.4rem;
    }
    &__txt{
      width: 80%;
      font-size: 1.2rem;
      line-height: 2.4rem;
      @media (--mobile) {
        display: none;
      }
    }
    &__btn{
      font-size: 1.3rem;
      display: flex;
      justify-content: flex-end;
      position: relative;
      span{
        position: relative;
        padding-bottom: 0.5rem;
      }
      span::after{
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
      }
      &::before{
        position: absolute;
        top: 0.7rem;
        right: 5.3rem;
        content: '';
        display: inline-block;
        width: 0.9rem;
        height: 0.9rem;
        border-top: 1px solid #000;
        transform: translateX(-50%);
      }
      &::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 0.9rem;
        height: 0.9rem;
        border-top: 1px solid #000;
        top: 0.3rem;
        right: 6.1rem;
        transform: rotate(90deg);
      }
    }
  }

  &-title{
    font-size: 3.2rem;
    margin-bottom: 5rem;
    text-align: center;
    letter-spacing: 0.2em;
    display: flex;
    justify-content: center;
    @media (--mobile) {
      margin-bottom: 3rem;
    }
  }
 
  &-reserve{
    width: 100rem;
    border: 5px solid #E3E3E3;
    padding: 6rem 0;
    margin: 0 auto 13rem auto;

    @media (--mobile) {
      width: 90%;
      margin-bottom: 5rem;
      padding: 15rem 0 5rem 0;
    }
    &__inner{
      display: flex;
      width: 89rem;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;
      @media (--mobile) {
        display: block;
        width: 100%;
      }
    }
    &__icon{
      width: 4.3rem;
      margin-right: 2rem;
      img{
        width: 100%;
      }
    }
    &__text{
      position: relative;
      p{
        font-size: 1.6rem;
        line-height: 2.8rem;
        padding-left: 10rem;
        @media (--mobile) {
          padding-left: 0;
          text-align: center;
          margin-bottom: 4rem;
          font-size: 1.4rem;
        }
        span{
          font-weight: bold;
        }
      }
      &::before{
        width: 7.9rem;
        height: 7.9rem;
        position: absolute;
        display: block;
        content: "";
        background: url(../images/top/icon-reservation.svg);
        background-repeat: no-repeat;
        background-size: cover;
        @media (--mobile) {
          top: -10rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    &__wrap {
      display: flex;
      flex-direction: column;
      gap: 2rem 0;
    }
    &__btn{
      width: 43rem;
      font-size: 1.8rem;
      text-align: center;
      position: relative;
      &::after{
        border-right: solid 3px #707070;
        border-top: solid 3px #707070;
        content: "";
        display: block;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        right: 3rem;
        top: 42%;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      @media (--mobile) {
        width: 90%;
        margin: 0 auto;
        font-size: 1.6rem;
      }

      &:nth-of-type( 1 ){
        a {
          @media (--mobile){
            padding: 2.6rem 0;
          }
        }
      }
      a{
        display: block;
        padding: 3.5rem 0;
        border: 3px solid #707070;
        background-color: #fff;
        border-radius: 5rem;
        transition: all .3s;
        &:hover{
          background-color: #E3E3E3;
        }
      }
    }
  }


}