//font style
//@import url('https://fonts.googleapis.com/earlyaccess/notosansjp.css');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;700&display=swap');

$fontfamily: 'Lato', sans-serif;

.YuGothic{
  font-family :
	YuGothic,    /* Mac用 */
	'Yu Gothic', /* Windows用 */
	sans-serif;
}

.YuMincho{
	font-family :
	YuMincho,    /* Mac用 */
	'Yu Mincho', /* Windows用 */
	serif;
}