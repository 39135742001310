.l-footer{
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #222222;
  color: #FFFFFF;
  @media (--mobile) {
    // padding: 0 3rem;
    margin-top: 10rem;
  }
  &-contact{
    position: relative;
    width: 100%;
    height: 64rem;
    background: url(../images/top/bg-contact.jpg);
    background-size: cover;
    color: #FFFFFF;
    padding: 15rem 0;
    @media (--mobile) {
      padding: 6rem 0 8rem;
      height: auto;
      background-position: center bottom;
    }
    &__title{
      font-size: 3.2rem;
      margin-bottom: 3rem;
      text-align: center;
      @media (--mobile) {
        font-size: 2.8rem;
        margin-bottom: 1.5rem;
      }
    }
    &__txt{
      margin-bottom: 7rem;
      @media (--mobile) {
        margin-bottom: 4rem;
      }
      p{
        font-size: 1.6rem;
        text-align: center;
        @media (--mobile) {
          line-height: 3.2rem;
          font-size: 1.4rem;
        }
      }
    }
    &__content{
      width: 87rem;
      display: flex;
      margin: 0 auto;
      @media (--mobile) {
        width: 90%;
        display: block;
      }
    }
    &__tel{
      border: 5px solid #707070;
      margin-right: 3rem;
      padding: 4rem 5rem;
      @media (--mobile) {
        margin-right: 0;
        margin-bottom: 2rem;
        padding: 4rem 1rem;
      }
      a{
        font-size: 3.7rem;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 2rem;
        display: block;
        position: relative;
        text-align: center;
        // padding: 4rem 5rem;
        transition: .3s opacity;
        span {
          position: relative;
          display: inline-block;
          padding-left: 40px;
          @media (--mobile) {
            padding-left: 0;
            padding-top: 5rem;
          }
          &::before{
            position: absolute;
            display: block;
            content: "";
            background: url(../images/common/icon-tel.svg);
            background-repeat: no-repeat;
            width: 3rem;
            left: 0;
            height: 4rem;
            @media (--mobile) {
              width: 2.5rem;
              background-size: cover;
              left: 50%;
              transform: translateX(-50%);
              top: 0;
            }
          }
        }
        &:hover{
          opacity: 0.6;
        }
      }
      p{
        font-size: 1.6rem;
        font-weight: bold;
        @media (--mobile) {
          text-align: center;
          line-height: 3.2rem;
        }
        span{
          display: block;
          font-weight: normal;
          font-size: 1.4rem;
          margin-top: 0.5rem;
          @media (--mobile) {
            margin-left: 0;
            line-height: 2.2rem;
            display: block;
          }
        }
      }
    }

    &__mail{
      border: 5px solid #707070;
      display: flex;
      @media (--mobile) {
        justify-content: center;
      }
      a{
        padding: 4rem 5rem;
        color: #FFFFFF;
        transition: .3s opacity;
        &:hover{
          opacity: 0.6;
        }
      }
      p{
        font-size: 1.6rem;
        font-weight: bold;
        position: relative;
        padding-top: 6rem;
        @media (--mobile) {
          font-size: 1.4rem;
        }
        &::before{
          position: absolute;
          display: block;
          content: "";
          background: url(../images/common/icon-mail.svg);
          background-repeat: no-repeat;
          background-size: cover;
          width: 5rem;
          left: 35%;
          height: 3rem;
          top: 0;
        }
      }
    }

    &__insta {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      width: 3rem;
      height: 3rem;
      transition: .3s;
      @media (--mobile){
        bottom: 2.5rem;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      @media (--large){
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  &__top{
    position: absolute;
    top: -120px;
    right: 40px;
    display: block;
    box-sizing: border-box;
    width: 60px;
    height: 60px;
    border: 1px solid #000;
    transition: all .3s;

    &:hover{
      background-color: #eee;
      border: 1px solid #eee;
    }
    @media (--mobile) {
      position: absolute;
      top: 5px;
      right: 30px;
      display: block;
      box-sizing: border-box;
    }
    img{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      transform: translate(-50%,-50%);
      height: auto;
    }
  }
  &__content{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2.5rem;
    padding:2.5rem 8rem 2.5rem;
    @media (--mobile) {
      width: 100%;
      flex-wrap: wrap;
      flex-direction: column;
      align-items: center;
      padding: 5rem 3rem 1rem;
    }
  }
  &__logo{
    width: 11rem;
    @media (--mobile) {
      width: 13rem;
      margin-bottom: 5rem;
    }
    img{
      width: 100%;
    }
  }
  &__address{
    font-size: 1.3rem;
    margin-bottom: 6rem;
  }
  &__copyright{
    font-size: 1.1rem;
    text-align: right;
    @media (--mobile) {
      bottom: 0;
      position: absolute;
      // left: 50%;
      // transform: translate(-50%, -50%);
      font-size: 1.3rem;
      padding-bottom: 3rem;
    }
  }

  &__nav{
    @media (--mobile) {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 4rem;
    }
  }
  ul.nav{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3.5rem;
    @media (--mobile) {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li{
      margin-right: 5rem;
      font-size: 1.2rem;
      &:last-child{
        margin-right: 0;
      }
      @media (--mobile) {
        width: 48%;
        margin-bottom: 2rem;
        margin-right: 0;
        font-size: 1.4rem;
      }
      a{
        color: #FFFFFF;
        transition: .3s opacity;
        &:hover{
          opacity: 0.6;
        }
      }
    }
  }
  ul.contact{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5rem;
    li{
      width: 2rem;
      margin-right: 3rem;
      &:last-child{
        margin-right: 0;
      }
      img{
        width: 100%;
      }
      transition: .3s opacity;
      &:hover{
        opacity: 0.6;
      }
    }
  }
}
#page-top {
  position: absolute;
  bottom: 13rem;
  right: 2rem;
  z-index: 99;
  @media (--mobile) {
    bottom: 3rem;
  }
}
#page-top a {
  width: 5rem;
  display: block;
  transition: all .3s ease;
}
#page-top a:hover {
  text-decoration: none;
  opacity: .5;
}