@charset "utf-8";

// Foundation
@import "foundation/setting/_font",
"foundation/setting/_mediaquery",
"foundation/setting/_timing",
"foundation/setting/_function",
"foundation/setting/_mixin",
"foundation/setting/_regulation",
"foundation/setting/_animation";

// vendor
@import "normalize.css"; /* == @import "../node_modules/normalize.css/normalize.css"; */
// @import "swiper/swiper-bundle.min.css";



@import "foundation/base/_vue";
@import "foundation/base/_structure";

// Layout
@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_main.scss";
@import "layout/_sidebar.scss";

//pages
@import "object/project/_news.scss";
@import "object/project/_top.scss";

// Object/Component
 
@import "object/component/_bread.scss";
@import "object/component/_btn.scss";
@import "object/component/_flex.scss";
@import "object/component/_form.scss";
@import "object/component/_iframe.scss";
@import "object/component/_pagination.scss";
@import "object/component/_sns.scss";
@import "object/component/_wordpress.scss";

// Object/Component
@import "object/utility/_helper.scss";

// debug
@import "foundation/base/_debug";
