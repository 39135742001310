.p-news{
  margin: 0 auto;
  &-mv{
    width: 100%;
    text-align: right;
    position: relative;
    // margin-top: 10rem;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin-bottom: 20rem;
    @media (--mobile) {
      margin-bottom: 6rem;
    }
    &__img01{
      width: 60%;
      margin-top: 12rem;

      img{
        width: 100%;
      }
      @media (--mobile) {
        width: 50%;
        padding-top: 7rem;
        margin-top: 0;
      }
    }
    &__img02{
      width: 15%;
      img{
        width: 100%;
      }
      @media (--mobile) {
        width: 20%;
        top: -1rem;
        left: 0;
        position: absolute;
      }
    }
    &__text{
      position: absolute;
      font-size: 2.5vw;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 40%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: left;
      @media (--mobile) {
        font-size: 7vw;
        width: 90%;
      }

    }
  }
  &-detail{
    width: 108rem;
    margin: 0 auto;
    @media (--mobile) {
      width: 100%;
      padding: 0 2rem;
    }
    &__title{
      border-bottom: 1px solid #000;
      padding-bottom: 1rem;
      margin-bottom: 3rem;

      p{
        font-size: 2rem;
      }
    }
    &__data{
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
    &__img{
      width: 100%;
      // display: flex;
      margin-bottom: 2rem;
      img{
        max-width: 108rem;
        object-fit: cover;
        @media (--mobile) {
          max-width: 100%;
        }
      }
    }
    &__category{
      ul{
        display: flex;

        li{
          margin-right: 1rem;
          border: 1px solid #000;
          padding: 0.5rem;
          &:last-child{
            margin-right: 0;
          }
        }
      }
    }
    &__text{
      font-size: 1.2rem;
      line-height: 2.4rem;
      margin-bottom: 5rem;
    }
    &__paging{
      width: 50rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      font-size: 1.2rem;
      margin-top: 10rem;
      margin-bottom: 10rem;
      @media (--mobile) {
        width: 100%;
      }
    }
  }

  &-list{
    width: 108rem;
    margin: 0 auto 15rem auto;
    @media (--mobile) {
      width: 100%;
      padding: 0 2rem;
      margin-bottom: 10rem;
    }
    ul{
      margin-top: 7rem;
      margin-bottom: 5rem;
      @media (--mobile) {
        margin-top: 3rem;
      }
      li{
        border-top: 1px solid #e8e8e8;
        width: 100%;
        
        &:last-child{
          border-bottom: 1px solid #e8e8e8;
        }
        a{
          padding: 3rem;
          display: flex;
          transition: all .3s;
          @media (--mobile) {
            padding: 2rem 0;
          }
          &:hover{
            background-color: #f9f9f9;
          }
        }
      }
    }
    &__img{
      margin-right: 10rem;
      @media (--mobile) {
        width: 10rem;
        margin-right: 2rem;
      }
      img{
        width: 18rem;
        height: 18rem;
        object-fit: cover;
      }
    }
    &__content{
      width: calc(100% - 18rem - 10rem);
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: center;
      @media (--mobile) {
        width: calc(100% - 10rem - 2rem);
      }
    }
    &__data{
      display: flex;
      margin-bottom: 2rem;
      @media (--mobile) {
        display: block;
      }
      span.date{
        margin-right: 5rem;
        font-size: 1.5rem;
        @media (--mobile) {
          font-size: 1.2rem;
          margin-bottom: 1rem;
          display: block;
        }
      }
      span.category{
        ul{
          display: flex;
          margin-top: 0;
          margin-bottom: 0;
          li{
            padding: 2px 2px;
            border: 1px solid #000;
            width: auto;
            font-size: 1rem;
            margin-right: 0.5rem;
          }
        }
      }
    }
    &__tit{
      margin-bottom: 2rem;
      font-size: 1.4rem;
    }
    &__txt{
      width: 80%;
      font-size: 1.2rem;
      line-height: 2.4rem;
      @media (--mobile) {
        display: none;
      }
    }
    &__btn{
      font-size: 1.3rem;
      display: flex;
      justify-content: flex-end;
      position: relative;
      font-family: 'Lato', sans-serif;
      span{
        position: relative;
        padding-bottom: 0.5rem;
      }
      span::after{
        content: '';
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
      }
      &::before{
        position: absolute;
        top: 7px;
        right: 53px;
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        border-top: 1px solid #000;
        transform: translateX(-50%);
      }
      &::after {
        position: absolute;
        content: '';
        display: inline-block;
        width: 9px;
        height: 9px;
        border-top: 1px solid #000;
        top: 3px;
        right: 61px;
        transform: rotate(90deg);
      }
    }
  }

}